
import { Vue, Prop, Component } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { RoomInfo } from "@/services/room.service";
import RoomDescItem from "@/components/room/RoomDescriptionItem.vue";

@Component({ components: { RoomDescItem } })
export default class RoomDescription extends Vue {
  @Prop({ type: Object as () => RoomInfo })
  private roomInfo!: RoomInfo;
}
