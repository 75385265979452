import RoomService, { LogsResponse, RoomInfo } from "@/services/room.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";
interface UseRoomDetailState {
  loading: boolean;
  room?: RoomInfo;
  logs: LogsResponse[];
  logColumns: object[];
}
interface UseRoomDetailParams {
  roomId: string;
}
const logColumns = [
  {
    title: "participate",
    dataIndex: "participate",
    scopedSlots: { customRender: "participate" },
    fixed: "left",
    width: 150
  },
  {
    title: "role",
    dataIndex: "participate.role",
    scopedSlots: { customRender: "role" },
    width: 150
  },
  {
    title: "action",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 200
  },
  {
    title: "currentEngine",
    dataIndex: "currentEngine",
    scopedSlots: { customRender: "currentEngine" },
    width: 100
  },
  {
    title: "engineAfter",
    dataIndex: "engineAfter",
    scopedSlots: { customRender: "engineAfter" },
    width: 100
  },
  {
    title: "time",
    dataIndex: "time",
    scopedSlots: { customRender: "time" },
    width: 100
  },
  {
    title: "Browser",
    dataIndex: "userAgent",
    scopedSlots: { customRender: "userAgent" },
    width: 200
  }
];
const useRoomDetail = ({ roomId }: UseRoomDetailParams) => {
  const state = reactive<UseRoomDetailState>({
    loading: true,
    room: undefined,
    logs: [],
    logColumns
  });

  const fetchRoom = async () => {
    const data = await RoomService.getRoomById(roomId);
    state.room = data;
  };

  const fetchLogs = async () => {
    const data = await RoomService.getLogs(roomId);
    state.logs = data;
  };

  onMounted(async () => {
    state.loading = true;
    await fetchRoom();
    await fetchLogs();
    state.loading = false;
  });
  return { ...toRefs(state), fetchRoom, fetchLogs };
};

export default useRoomDetail;
