
import hexToRgb from "@/helpers/hex-to-rgb";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class RoomDescriptionItem extends Vue {
  @Prop({ type: String, default: "" })
  private label?: string;

  @Prop({ type: String, default: "" })
  private text?: string;

  @Prop({ type: Boolean, default: false })
  private value?: boolean;

  @Prop({ type: String, default: "string" })
  private type?: string; // string | date | link | color

  codeColor(colorhex: string) {
    const color = hexToRgb(colorhex);
    return `rgb(${color?.r},  ${color?.g}, ${color?.b})` || "rgb(0, 0, 0)";
  }
}
